import React, { ChangeEvent, useState } from "react";

import Accept from "./Accept";
import Status from "./Status";

interface Props {
  authenticity_token: string;
  event: SignupEvent;
  signup: Signup;
  type: SignupFormType;
  url: string;
}

const blankSignup = {
  consent: false,
  newsletter: false,
  name: null,
  companion: null,
  extra_field: null,
  selected_option: null
};

function submittable(type: SignupFormType, event: SignupEvent, signup: Signup) {
  const extraField = !event.extra_field_label || signup.extra_field;
  const selectedOption =
    event.selectable_options.length == 0 || signup.selected_option;
  if (type === "invite") {
    return (
      signup.status === "declined" ||
      (signup.status === "accepted" &&
        signup.name &&
        signup.consent &&
        extraField &&
        selectedOption)
    );
  } else {
    return (
      signup.email &&
      validEmail(signup.email) &&
      signup.name &&
      signup.consent &&
      extraField &&
      selectedOption
    );
  }
}

function validEmail(email: string) {
  return email.match(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i);
}

export default function Form(props: Props) {
  const { event, url, type } = props;

  const [signupState, setSignup] = useState(props.signup);

  const signup =
    signupState.status === "declined"
      ? { ...signupState, ...blankSignup }
      : signupState;

  const handleChange =
    (attr: string) => (evt: ChangeEvent<HTMLInputElement>) => {
      if (evt.target.type === "checkbox") {
        setSignup({ ...signupState, [attr]: evt.target.checked });
      } else {
        setSignup({ ...signupState, [attr]: evt.target.value });
      }
    };

  const setStatus = (newStatus: SignupStatus) => {
    setSignup({ ...signupState, status: newStatus });
  };

  return (
    <form acceptCharset="UTF-8" action={url} method="post">
      <input
        type="hidden"
        name="authenticity_token"
        value={props.authenticity_token}
        autoComplete="off"
      />
      {type === "invite" && (
        <input type="hidden" name="_method" value="put" autoComplete="off" />
      )}
      <h2>Påmelding</h2>
      {type === "invite" && <Status signup={signup} setStatus={setStatus} />}
      <Accept
        disabled={signup.status === "declined"}
        email={type === "open"}
        event={event}
        signup={signup}
        handleChange={handleChange}
      />
      <button type="submit" disabled={!submittable(type, event, signup)}>
        Send
      </button>
    </form>
  );
}
